.Hero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  // max-width: 1600px;
  justify-content: center;
  min-height: 80vh;
  gap: 100px;
  padding: 4rem 0;
  background-color: #dee8f1;
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &-contentImg {
    display: flex;
    justify-content: center;
    align-items: center;
    & .rotating_text {
      z-index: 200;
      animation-iteration-count: infinite;
      animation-duration: 5s;
      animation-timing-function: linear;
      &:hover {
        animation-name: rotate-s-loader;
        cursor: pointer;
      }
    }
    & .dev_image {
      position: absolute;
    }
  }
  &-contentHeading {
    font-size: 10vh;
    font-family: "Bricolage Grotesque", sans-serif;
    position: relative;
    z-index: 1;
    line-height: 114%;
    margin: 0;
    text-align: left;
  }
  &-contentPara {
    max-width: 540px;
    margin-bottom: 1.25rem;
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    line-height: 140%;
  }

  &-contentCTA {
    display: flex;
    flex-direction: row;
    & .customButton {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
  &-slides {
    margin-right: 0;
    height: 648px;
    display: flex;
    align-items: center;
  }

  &-slidesImage {
    height: 90%;
    width: 90%;
    overflow: hidden;
    border-radius: 12px;
    transition: all 0.2s linear;

    &:hover {
      scale: 1.1;
    }
  }
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
