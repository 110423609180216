.Hero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  justify-content: center;
  min-height: 80vh;
  gap: 100px;
  padding: 4rem 0;
  background-color: #dee8f1;
}
.Hero-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Hero-contentImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Hero-contentImg .rotating_text {
  z-index: 200;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
}
.Hero-contentImg .rotating_text:hover {
  animation-name: rotate-s-loader;
  cursor: pointer;
}
.Hero-contentImg .dev_image {
  position: absolute;
}
.Hero-contentHeading {
  font-size: 10vh;
  font-family: "Bricolage Grotesque", sans-serif;
  position: relative;
  z-index: 1;
  line-height: 114%;
  margin: 0;
  text-align: left;
}
.Hero-contentPara {
  max-width: 540px;
  margin-bottom: 1.25rem;
  font-size: 20px;
  text-align: left;
  font-weight: 500;
  line-height: 140%;
}
.Hero-contentCTA {
  display: flex;
  flex-direction: row;
}
.Hero-contentCTA .customButton:first-child {
  margin-right: 12px;
}
.Hero-slides {
  margin-right: 0;
  height: 648px;
  display: flex;
  align-items: center;
}
.Hero-slidesImage {
  height: 90%;
  width: 90%;
  overflow: hidden;
  border-radius: 12px;
  transition: all 0.2s linear;
}
.Hero-slidesImage:hover {
  scale: 1.1;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=Hero.css.map */