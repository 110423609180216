.Currentwork {
  padding-top: 8rem;
  border-radius: 64px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;

  &-content {
  }
  &-Logo {
    margin-top: 24px;
    margin-right: 16px;
  }
  &-Title {
    text-align: center;
    padding-top: 12px;
  }
  &-arrow {
    position: relative;
    top: 20px;
    left: 8px;
  }
  &-codeDev {
    position: relative;
    top: 20px;
    left: 14px;
    transform: rotate(4deg);
  }
  &-exp {
    display: flex;

    flex-direction: row;
    gap: 32px;
    max-width: 1600px;
    justify-content: space-evenly;
    @media only screen and (max-width: 1440px) {
      flex-wrap: wrap;
    }
  }
  &-para {
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 1000px;
    margin-top: 20px;
  }
}

.Cunrrentwork-showcase {
  margin-top: 64px;
  margin-bottom: 64px;
  max-width: 1600px;
  &Img {
    width: 100%;
  }
}

.OtherWork {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-row {
    display: flex;
    flex-direction: row;
    gap: 64px;
    width: 80vw;
    max-width: 1600px;
    align-items: center;
    margin-bottom: 64px;
  }

  &-bigcard {
    width: 100%;
    min-height: 60vh;
    max-height: 100vh;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    background: url("./assets/sgiImg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-width: 480px;
  }
  &-smallcard {
    width: 100%;
    min-width: 480px;
    min-height: 60vh;
    max-height: 100vh;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    background: url("./assets/sgiImg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &-Title {
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 16px;
  }
}

.card {
  width: 50%;
  min-width: 480px;
}
