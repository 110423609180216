@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,400;12..96,600&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "DM Sans", "helvetica", "arial", sans-serif;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
  background-color: #dee8f1;
}

.Caption {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.42em;
  text-transform: uppercase;
}

.Title {
  font-family: DM Sans;
  font-size: 64px;
  font-weight: 500;
  line-height: 85px;
  letter-spacing: 0em;
}

.Mono {
  font-family: DM Mono;
  font-weight: 500;
}

.Display {
  font-family: Bricolage Grotesque;
  font-weight: 600;
}
